import React from 'react';

function AboutPage() {
    return (
        <>
        <h2>About Page</h2>
        <p>Family Ring is an application that helps people store and retain their memories. </p>
        <p>The application is currently in private beta, if you require more information please contact support at: </p>
        Contact: <a href="mailto:support@familyring.io">dave@howellco.io</a>
        </>
    );
}

export default AboutPage;