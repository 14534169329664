import React from "react";

function LegalDocs() {
    return (
        <div className="center">
            <br />
            <h2>Legal Documents</h2>
            <br />  
            <ul className="left">
                <li><a href="/legalDocs/TermsAndConditions">Terms and Conditions</a></li>
                <li><a href="/legalDocs/PrivacyPolicy">Privacy Policy</a></li>
                <li><a href="/legalDocs/BetaAgreement">Beta Agreement</a></li>
            </ul>
        </div>
    );
}


export default LegalDocs;