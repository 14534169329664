import '../css/SupportPage.css'

function PrivacyPolicy() {
    return (
        <div className="center">
            <h1 className="title">Privacy Policy</h1>
            <br />
            <p className="left">Family Ring Privacy Policy</p>
            <p className="left">Effective Date: July 14, 2024</p>
            <p className="left">Last Updated: July 14, 2024</p>
            <br />
            <p className="left">Family Ring ("we", "us", "our") is committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our app.</p>
            <br />
            <p className="left">1. Information We Collect</p>
            <p className="left">1.1 Personal Information:</p>
            <p className="left">When you register for an account, we may collect personal information such as your name, email address, date of birth, and phone number.</p>
            <br />
            <p className="left">1.2 Usage Data:</p>
            <p className="left">We collect information about your interactions with the app, such as the features you use, the content you view, and your activity within the app.</p>
            <br />
            <p className="left">2. How We Use Your Information</p>
            <ul className="left">To provide, maintain, and improve Family Ring.</ul>
            <ul className="left">To communicate with you, including sending updates and promotional materials</ul>
            <ul className="left">To monitor and analyze usage and trends to improve your experience.</ul>
            <ul className="left">To protect the security and integrity of Family Ring.</ul>
            <br />
            <p className="left">3. Sharing Your Information</p>
            <p className="left">We do not share your personal information with third parties except as described in this policy.</p>
            <p className="left">We may share your information with service providers who perform services on our behalf.</p>
            <p className="left">We may disclose your information if required to do so by law or in response to valid requests by public authorities or to protect our rights.</p>
            <br />
            <p className="left">4. Data Security</p>
            <p className="left">We take reasonable measures to protect your information from unauthorized access, alteration, disclosure, or destruction. However, no method of transmission over the internet or electronic storage is 100% secure.</p>
            <br />
            <p className="left">5. Your Rights</p>
            <p className="left">You have the right to access, correct, update, or delete your personal information. You may do this by logging into your account or contacting us at <a href="mailto:support@familyring.io">Family Ring Support</a></p>
            <br />
            <p className="left">6. Children's Privacy</p>
            <p className="left">At this time Family Ring is not intended for children under 13. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us so that we can delete the information.</p>
            <br />
            <p className="left">7. Changes to Privacy Policy</p>
            <p className="left">We reserve the right to modify this Privacy Policy at any time. If we make changes, we will notify you by revising the "Last Updated" date at the top of this policy.</p>
            <p className="left">We will notify you of any changes by posting the new policy on Family Ring. Your continued use of the app after such changes constitutes your acceptance of the new policy.</p>
            <br />
            <p className="left">Contact Us</p>
            <p className="left">If you have any questions about this Privacy Policy, please contact us at <a href="mailto:support@familyring.io">Family Ring Support</a></p>
        </div>
    );
}

export default PrivacyPolicy;
