import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function DownloadPage() {
    const navigate = useNavigate();

    useEffect(() => {
        // Check local storage for terms acceptance
        const termsAccepted = localStorage.getItem('termsAccepted');
        if (termsAccepted !== 'yes') {
            // Redirect them to the terms and conditions page if not accepted
            navigate('/install');
        }
    }, [navigate]);

    return (
        <div>
            <h1>Welcome to the Actual Install Page</h1>
            {/* Your page content */}
        </div>
    );
}

export default DownloadPage;
