// This component is displayed when the user tries to access the app from a browser.
function InstallPage() {
    return (
        <div className="center">
            <h1 className="title">Family Ring</h1>
            <img src="/MemboxPersonInHeart.png" alt="Family Ring Logo" className="logo"/>
            <p className="description">Family Ring</p>
            <p className="description">Family Ring is in Beta.</p>
            <p className="description">You can install Family Ring for iOS at the Apple app store.</p>
            <p className="description">INSTALL LINK HERE</p>
            <br />
            <br />
            <p className="description">
            Contact: <a href="mailto:support@familyring.io">support@familyring.io</a>
            </p>
        </div>
    );
}

export default InstallPage;
