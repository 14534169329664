import './App.css';
import HomePage from "./components/HomePage";
import InstallPage from "./components/InstallPage";
import DownloadPage from "./components/DownloadPage"; 
import AboutPage from "./components/AboutPage";
import SupportPage from "./components/SupportPage";
import Header from "./components/Header";
import LegalDocs from "./components/LegalDocs";
import TermsAndConditions from "./components/TermsAndConditions";
import PrivacyPolicy from "./components/PrivacyPolicy";
import BetaAgreement from "./components/BetaAgreement";
import Container from 'react-bootstrap/Container';
import { Route, Routes } from "react-router-dom";


function App() {
  return (
    <Container fluid>
      <Header />
      <Routes>
        <Route path="/" element={<HomePage/>} />
        <Route path="/install" element={<InstallPage />} />
        <Route path="/install/actual" element={<DownloadPage />} />  
        <Route path="/about" element={<AboutPage/>} />
        <Route path="/support" element={<SupportPage/>} />
        <Route path="/legalDocs" element={<LegalDocs/>} />
        <Route path="/legalDocs/TermsAndConditions" element={<TermsAndConditions/>} />
        <Route path="/legalDocs/PrivacyPolicy" element={<PrivacyPolicy/>} />
        <Route path="/legalDocs/BetaAgreement" element={<BetaAgreement/>} />
      </Routes>
    </Container>
  );
}

export default App;

