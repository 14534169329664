import React from 'react';
import '../css/HomePage.css'

function HomePage() {
    return (
        <div className="center">
            <h1 className="title">Family Ring</h1>
            <img src="/MemboxPersonInHeart.png" alt="Family Ring Logo" className="logo"/>
            <p className="description">Family Ring</p>
            <p className="description"></p>
            <p className="description">Family Ring is an online social network focused on preserving family histories and personal stories.</p>
            <br />
            <br />
            <p className="description">
            Contact: <a href="mailto:support@familyring.io">Family Ring Support</a>
            </p>
        </div>
    );
}


export default HomePage;