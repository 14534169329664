import '../css/SupportPage.css'

function TermsAndConditions() {
    return (
        <div className="center">
            <h1 className="title">Terms and Conditions</h1>
            <br />
            <p className="left">Family Ring Terms and Conditions</p>
            <p className="left">Effective Date: July 14, 2024</p>
            <p className="left">Last Updated: July 14, 2024</p>
            <br />
            <p className="left">Welcome to Family Ring! These terms and conditions outline the rules and regulations for the use of Family Ring's app and services.</p>
            <br />
            <p className="left">1. Acceptance of Terms</p>
            <p className="left">By accessing and using Family Ring, you accept and agree to be bound by these terms and conditions. If you do not agree to these terms, please do not use the app.</p>
            <br />
            <p className="left">2. User Obligations</p>
            <p className="left">You must be at least 13 years old to use Family Ring. If you are under 13, you must have verifiable parental consent to use the app.</p>
            <p className="left">You agree to use Family Ring in accordance with all applicable laws and regulations.</p>
            <p className="left">You must not use Family Ring for any unlawful or prohibited activities.</p>
            <p className="left">You are responsible for maintaining the confidentiality of your account information and password.</p>
            <br />
            <p className="left">3. Intellectual Property</p>
            <p className="left">All content, trademarks, and data on Family Ring, including software, text, graphics, logos, and images, are the property of Family Ring or its licensors.</p>
            <p className="left">You may not reproduce, distribute, or create derivative works from any content on Family Ring without our prior written consent.</p>
            <br />
            <p className="left">4. Termination</p>
            <p className="left">We may terminate or suspend your access to Family Ring at any time, without prior notice or liability, for any reason, including if you breach these terms.</p>
            <br />
            <p className="left">5. Limitation of Liability</p>
            <p className="left">Family Ring is provided on an "as is" and "as available" basis. We do not warrant that the app will be uninterrupted, secure, or error-free.</p>
            <p className="left">To the maximum extent permitted by law, Family Ring and its affiliates shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues.</p>
            <br />
            <p className="left">6. Governing Law</p>
            <p className="left">These terms and conditions are governed by and construed in accordance with the laws of United States of America, Washington State, and you irrevocably submit to the exclusive jurisdiction of the courts in that location.</p>
            <br />
            <p className="left">7. Changes to Terms</p>
            <p className="left">We reserve the right to modify these terms at any time. We will notify you of any changes by posting the new terms on Family Ring. Your continued use of the app after such changes constitutes your acceptance of the new terms.</p>
            <br />
            <p className="left">Contact Us</p>
            <p className="left">If you have any questions about these terms, please contact us at  <a href="mailto:support@familyring.io">support@familyring.io</a></p>
        </div>
    );
}

export default TermsAndConditions;
