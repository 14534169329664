import '../css/SupportPage.css'

function BetaAgreement() {
    return (
        <div className="center">
            <h1 className="title">Beta Agreement</h1>
            <br />
            <p className="left">Family Ring Beta Agreement</p>
            <p className="left">Effective Date: July 14, 2024</p>
            <p className="left">Last Updated: July 14, 2024</p>
            <br />
            <p className="left">Thank you for participating in the Family Ring beta program. By participating in this beta test, you agree to the following terms and conditions.</p>
            <br />
            <p className="left">1. Introduction</p>
            <p className="left">This Beta Agreement ("Agreement") governs your participation in the Family Ring beta program ("Beta Program"). By participating, you agree to be bound by these terms.</p>
            <br />
            <p className="left">2. Beta Testing Participation</p>
            <p className="left">As a beta tester, you acknowledge that the software provided ("Beta Software") is a pre-release version and is not yet commercially available. Your participation is voluntary and may be terminated by you or Family Ring at any time.</p>
            <br />
            <p className="left">3. User Feedback</p>
            <p className="left">You agree to provide feedback regarding your use of the Beta Software. This feedback may include bug reports, suggestions, and general comments. Family Ring may use this feedback to improve the Beta Software.</p>
            <br />
            <p className="left">4. Confidentiality</p>
            <p className="left">You agree to keep all information regarding the Beta Software confidential. This includes not sharing screenshots, discussing features, or disclosing any details to third parties without Family Ring's written permission.</p>
            <br />
            <p className="left">5. Disclaimer of Warranties</p>
            <p className="left">The Beta Software is provided "as is" without any warranties, express or implied. Family Ring does not guarantee that the Beta Software will be free from bugs, errors, or other issues. Family Ring does not guarantee that your data stored in the application will be maintained without loss, corruption, or error.</p>
            <br />
            <p className="left">6. Limitation of Liability</p>
            <p className="left">To the maximum extent permitted by law, Family Ring shall not be liable for any damages arising from your use of the Beta Software, including but not limited to direct, indirect, incidental, or consequential damages.</p>
            <br />
            <p className="left">7. Termination</p>
            <p className="left">Family Ring reserves the right to terminate this Agreement and your access to the Beta Software at any time, for any reason, without prior notice.</p>
            <br />
            <p className="left">8. Governing Law</p>
            <p className="left">This Agreement is governed by the laws of United States of America, Washington State, without regard to its conflict of laws principles. Any disputes arising from this Agreement shall be resolved in the courts of that location.</p>
            <br />
            <p className="left">Contact Us</p>
            <p className="left">If you have any questions about this Agreement, please contact us at  <a href="mailto:support@familyring.io">support@familyring.io</a></p>
        </div>
    );
}

export default BetaAgreement;
