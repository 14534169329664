import React from "react";

function Header () {
    return (
        <nav>
            <a href="/">Home</a> | <a href="/install">Install</a>  |<a href="/about">About</a>  | <a href="/support">Support</a>  | <a href="/legalDocs">Legal Documents</a> 
        </nav>
    );
}

export default Header;
